import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Text, Label, View, Flex, Divider, Button, Link, Image, Icon, Accordion, useTheme } from '@aws-amplify/ui-react';
import { MdSearch, MdLogout } from "react-icons/md";
import { RxExternalLink } from "react-icons/rx";
import { FaRegUser } from "react-icons/fa6";
import { useTokens } from '../components/AuthPage'; // AuthPageからuseTokensをインポート
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import '../css/Header.css'
import { AiOutlineMenu } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import IcoPdf from '../image/ico_pdf.svg';
import IcoPdfDisabled  from '../image/ico_pdf_disabled.svg';
import IcoPdfHover  from '../image/ico_pdf_hover.svg';
import IcoLogo from '../image/logo.svg';

type HeaderProps = {
  setIsLoadedHeader: (flag:boolean) => void;
  reloadFlag?: boolean;
};

export default function Header(props:HeaderProps) {
  const {tokens} = useTheme();
  const navigate = useNavigate();
  
  const { getTokens } = useTokens(); // useTokensフックを使用
  const [tokenInfo, setTokenInfo] = React.useState<any>(null);
  
  // ヘッダ読み込み時処理完了フラグ
  const setIsLoadedHeader = props.setIsLoadedHeader;
  
  // アカウントステータス
  const [isStatusOk, setIsStatusOk] = React.useState(false);
  
  //ダウンロード件数
  const [downloadCountGive, setDownloadCountGive] = React.useState(0);
  const [downloadCountUse, setDownloadCountUse] =  React.useState(0);
  
  const [isOpenHeaderMenu,setIsOpenHeaderMenu] = React.useState<boolean>(false);
  
  // エラーメッセージ
  const ERROR_MESSAGE_PROCESSING_ERROR = "処理中にエラーが発生しました。もう一度お試し下さい。";
  
  // ステータスのチェック
  const callCheckAccountStatus = async (token:string | null) => {
    const baseUrl:string = process.env.REACT_APP_API_BASE_URL || '';
    const path:string = '/common/checkaccountstatus';
    let errorMessage = '';
    try {
      const response = await fetch(baseUrl.concat(path),{
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`, //アクセストークン
          'Content-Type': 'application/json;'
        },
      });
      if (response.ok) {
        const result = await response.json();
        if ('teamStatus' in result) {
          if (result.teamStatus) {
            setIsStatusOk(result.teamStatus);
          } else {
            setIsStatusOk(result.teamStatus);
            if ('message' in result) {
              alert(result.message);
              window.location.href = process.env.REACT_APP_MYACCOUNT_URL + "/applications";
            } else {
              const result = {hasError:true};
              return result;
            }
          }
        } else {
          const result = {hasError:true};
          return result;
        }
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      } 
    } catch (error) {
      console.error(error);
      const result = {hasError:true};
      return result;
    }
  };
  
  // 新規アカウント・プラン変更チェック
  const callAccountManage = async (token:string | null) => {
    const baseUrl:string = process.env.REACT_APP_API_BASE_URL || '';
    const path:string = '/common/account';
    try {
      const response = await fetch(baseUrl.concat(path),{
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`, //アクセストークン
          'Content-Type': 'application/json;'
        },
      });
      if (response.ok) {
        const result = await response.json();
        return result;
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error(error);
      const result = {errorMessage: "処理中にエラーが発生しました。画面を再読み込みしてください。"};
      return result;
    }
  };
  
  // ダウンロード残数情報の取得
  const callGetOutputManage = async (token:string | null, targetDate:string) => {
    const baseUrl:string = process.env.REACT_APP_API_BASE_URL || '';
    const path:string = '/postgres/getoutputmanage';
    const query:string = `?targetDate=${encodeURIComponent(targetDate)}`
    try {
      const response = await fetch(baseUrl.concat(path).concat(query),{
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`, //アクセストークン
          'Content-Type': 'application/json;'
        },
      });
      if (response.ok) {
        const result = await response.json();
        return result;
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error(error);
      const result = {message: "処理中にエラーが発生しました。画面を再読み込みしてください。"};
      return result;
    }
  };
  
  // ヘッダ読み込み時処理
  React.useEffect(() => {
    const handleLoadHeader = async () => {
      // 最新のアクセストークンを取得する
      const {decodedAccessTokenInfo, idToken} = await getTokens();
      setTokenInfo(decodedAccessTokenInfo);
      
      // アクセストークンのconsole
      console.log("Decoded Access Token Info:", decodedAccessTokenInfo);
      
      // ステータスのチェック
      const callCheckAccountStatusResult = await callCheckAccountStatus(idToken);
      if (callCheckAccountStatusResult && 'hasError' in callCheckAccountStatusResult) {
        alert("処理中にエラーが発生しました。画面を再読み込みしてください。");
        return;
      }
      
      // 新規アカウント・プラン変更チェック
      const accountManageData = await callAccountManage(idToken);
      if (accountManageData && 'errorMessage' in accountManageData) {
        const errorMessage = accountManageData.errorMessage;
        if (errorMessage && errorMessage != '') {
          alert(errorMessage);
          return;
        }
      } else if (accountManageData && 'hasError' in accountManageData && 'message' in accountManageData) {
        if (accountManageData.hasError) {
          const message = accountManageData.message;
          if (message && message != '') {
            alert(message);
            return;
          }
        } else {
          const message = accountManageData.message;
          if (message && message != '') {
            alert(message);
          }
        }
      }
      
      // ダウンロード残数情報の取得
      const now = new Date();
      const formattedNow = format(toZonedTime(now, '+09:00'), 'yyyy/MM');
      const outputManageData = await callGetOutputManage(idToken, formattedNow);
      if (outputManageData) {
        if ('message' in outputManageData) {
              const message = outputManageData.message;
              if (message && message != '') {
                alert(message);
                return;
              }
        }
        if ('outputCount_give' in outputManageData) {
          setDownloadCountGive(outputManageData.outputCount_give);
        }
        if ('outputCount_use' in outputManageData) {
          setDownloadCountUse(outputManageData.outputCount_use);
        }
      }
      // 画面内容の初期化
      
      setIsLoadedHeader(true);
    }
    
    const handleReloadHeader = async () => {
      // 最新のアクセストークンを取得する
      const {decodedAccessTokenInfo, idToken} = await getTokens();
      setTokenInfo(decodedAccessTokenInfo);
      
      // アクセストークンのconsole
      console.log("Decoded Access Token Info:", decodedAccessTokenInfo);
      
      // ダウンロード残数情報の取得
      const now = new Date();
      const formattedNow = format(toZonedTime(now, '+09:00'), 'yyyy/MM');
      const outputManageData = await callGetOutputManage(idToken, formattedNow);
      if (outputManageData) {
        if ('message' in outputManageData) {
              const message = outputManageData.message;
              if (message && message != '') {
                alert(message);
                return;
              }
        }
        if ('outputCount_give' in outputManageData) {
          setDownloadCountGive(outputManageData.outputCount_give);
        }
        if ('outputCount_use' in outputManageData) {
          setDownloadCountUse(outputManageData.outputCount_use);
        }
      }
    }
    
    if (props.reloadFlag === undefined || !props.reloadFlag) {
      handleLoadHeader();
    } else {
      handleReloadHeader();
    }
    
  },[props.reloadFlag]);
  
  // ホーム画面へ遷移
  const handleNavigateHomeScreen = () => {
    navigate("/");
  };
  // 企業INDEXナビとは？を別タブで開く
  const handleOpenGuidePage = () => {
    window.open(`${process.env.REACT_APP_OPENSITE_BASE_URL}/about.html`, "_blank", "noreferrer");
  };
  // 活用ガイドを別タブで開く
  const handleOpenGuideUsage = () => {
    window.open(`${process.env.REACT_APP_OPENSITE_BASE_URL}/download/guide/cnavi-guide-usage.pdf`, "_blank", "noreferrer");
  };
    // 活用のコツを別タブで開く
  const handleOpenUsageTips = () => {
    window.open(`${process.env.REACT_APP_OPENSITE_BASE_URL}/useful/technique/index.html`, "_blank", "noreferrer");
  };

  // G-Searchデータベースサービスの呼び出し
  const handleOpenGSearchDBS = async () => {
    const newWindow = window.open('', '_blank');
    try {
      if (tokenInfo) {
        if (tokenInfo.plan == 'free') {
          newWindow?.close();
          handleNavigatePlanChange();
        }
        else if (tokenInfo.available_features=='disabled') {
          newWindow?.close();
          alert("ただいまお客様のアカウントではG-Searchコンテンツをご利用いただけません。\n詳細については、メールをご確認ください。");
          return;
        } else {
          const {idToken} = await getTokens();
          const baseUrl:string = process.env.REACT_APP_API_BASE_URL || '';
          const path:string = '/common/gsearch';
          const now = new Date();
          const formattedNow = format(toZonedTime(now, '+09:00'), 'yyyyMMddHHmmss');
          const response = await fetch(baseUrl.concat(path),{
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${idToken}`, //アクセストークン
              'Content-Type': 'application/json;'
            },
            body: JSON.stringify({dbid: 'WTOP', T:formattedNow})
          });
          if (response.ok) {
            const result = await response.json();
            if (newWindow) newWindow.location.href = result.headers.Location
            return;
          }
          newWindow?.close();
          alert(ERROR_MESSAGE_PROCESSING_ERROR);
          return;
        }
      }
    } catch (error) {
      newWindow?.close();
      console.error('gsearchエラー:', error);
      alert(ERROR_MESSAGE_PROCESSING_ERROR);
      return;
    }
  };
  
  // ログアウトボタン押下時処理
  const handleLogout = () => {
    navigate("/logout");
  };
  
  // G-Horizonの「登録内容確認・変更」画面を別タブで開く
  const handleOpenProfile = () => {
    window.open(process.env.REACT_APP_MYACCOUNT_URL + "/profile", "_blank", "noreferrer");
  };
  // 追加購入画面へ遷移
  const handleNavigateAddPurchase = () => {
    navigate("/addpurchase");
  };
  // ダウンロード履歴一覧ページ画面へ遷移
  const handleNavigateDownloadLog = () => {
    navigate("/downloadlog");
  };
  // 保存検索条件一覧ページ画面へ遷移
  const handleNavigateSearchFilterList = () => {
    navigate("/searchfilterlist");
  };
  // プラン変更画面へ遷移
  const handleNavigatePlanChange = () => {
    navigate("/planchange");
  };
  // G-Horizonの「利用状況照会」画面を別タブで開く
  const handleOpenGHorizonPurchase = () => {
    window.open(process.env.REACT_APP_MYACCOUNT_URL + "/eui/874d0a78-b42a-4d22-8f58-ee05ca589650", "_blank", "noreferrer");
  };
  
  return (
    <Flex as="header" className="Header" position="sticky" top="0" style={{zIndex:99}}>
      <View className="pc" width="100%">
        <Flex justifyContent="space-between" width="100%" padding={{base:"0 1.4rem", large:"0 0 0 2.4rem"}} backgroundColor={tokens.colors.white} position="relative" height={{base:"55px",large:"auto"}} lineHeight={{base:"55px",large:"normal"}} style={{zIndex:500}}>
          <Flex className="Logo" padding="0" height="55px">
            <Link className="headerLogoLink" tabIndex={0} padding="0" onClick={handleNavigateHomeScreen} display="flex" style={{alignItems:"center"}}
              onKeyPress={(e: React.KeyboardEvent<HTMLElement>) => {
                if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    handleNavigateHomeScreen();
                }
            }}>
              <Image alt="企業INDEXナビのロゴ" src={IcoLogo} width="144px" height="40px"></Image>
            </Link>
          </Flex>
          <Flex gap="3rem">
              <Flex display="inline-flex" alignItems="center" gap={{base:"1.5rem",large:"3rem"}}>
                {tokenInfo?(
                <Flex display={{base:"none",large:"inline-flex"}} alignItems="baseline" gap="0.75rem">
                  <Text fontSize={tokens.fontSizes.xxl} fontWeight="bold">{tokenInfo.user_name}</Text>
                  <Text fontSize={tokens.fontSizes.large} fontWeight="bold">さん</Text>
                </Flex>
                ):(<></>)}
                <Flex className="logOutButton" height="25px" tabIndex={0} gap="0.2rem" marginTop="0.2rem" alignItems="center" onClick={handleLogout} isDisabled={!isStatusOk}
                  onKeyPress={(e: React.KeyboardEvent<HTMLElement>) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                        e.preventDefault();
                        handleLogout();
                    }
                }}>
                   <MdLogout style={{width:"1em",height:"1.2em"}}/>
                   <Text fontSize="1.2rem" fontWeight={tokens.fontWeights.bold}>ログアウト</Text>
                </Flex>

                  <View as="button" className="sp_menu_btn open" onClick={() => setIsOpenHeaderMenu(!isOpenHeaderMenu)}>
                    <View as="span" className={isOpenHeaderMenu ? "sp_menu_btn_line open" : "sp_menu_btn_line"}></View>
                  </View>
              
              </Flex>
              <Flex display={{base:"none",large:"inline-flex"}} direction="column" gap="0" width="auto" minWidth="20rem" paddingTop="0.5rem" textAlign="center" alignItems="center" backgroundColor={tokens.colors.blue[10]}>
                {isStatusOk?(
                <>
                  <Text as="span" minWidth="20rem"  fontWeight={tokens.fontWeights.bold} fontSize={tokens.fontSizes.large}>詳細表示・ダウンロード件数</Text>
                  <Flex direction="row" height="30px" transform="translateY(-5px)" gap="0.5rem">
                    <Text as="span" color={tokens.colors.blue[60]} fontSize="2.7rem" height="3.5rem" lineHeight="3.5rem" fontWeight={tokens.fontWeights.bold}>{downloadCountUse}/{downloadCountGive}</Text>
                    <Text as="span" fontSize={tokens.fontSizes.large} fontWeight={tokens.fontWeights.bold} lineHeight="45px">件</Text>
                  </Flex>
                </>):(<></>)}
              </Flex>
          </Flex>
        </Flex>
          
        <View as="div" display={{base:"block",large:"none"}} padding="0 10px 0 15px" backgroundColor={tokens.colors.white} style={{borderBottom:`2px solid ${tokens.colors.blue[60]}`}}> 
          <Flex display="inline-flex" alignItems="center" padding="5px 0" gap="0" width="100%" justifyContent="space-between" style={{borderTop:`2px solid ${tokens.colors.neutral[40]}`}}>
              {tokenInfo?(
                <Text margin="0" maxWidth="35%" marginLeft="10px">
                  <Text as="span" fontSize={tokens.fontSizes.xxl} fontWeight={tokens.fontWeights.bold} style={{overflowWrap:"anywhere"}}>{tokenInfo.user_name}</Text>
                  <Text as="span" fontSize={tokens.fontSizes.large} fontWeight={tokens.fontWeights.bold} marginLeft="3px">さん</Text>
                </Text>
                ):(<></>)}
              <Flex direction="column" minWidth="10rem" gap="0" width="auto" textAlign="center" maxWidth="65%" alignItems="center">
                {isStatusOk?(
                  <>
                    <Text as="span" fontWeight={tokens.fontWeights.bold} fontSize={tokens.fontSizes.large}>詳細表示・ダウンロード件数</Text>
                    <Text margin="0" width="100%" >
                      <Text as="span" color={tokens.colors.blue[60]} fontSize="2.7rem" lineHeight="2.7rem" fontWeight={tokens.fontWeights.bold} style={{overflowWrap:"anywhere"}}>{downloadCountUse}/{downloadCountGive}</Text>
                      <Text as="span" fontSize={tokens.fontSizes.large} fontWeight={tokens.fontWeights.bold} marginLeft="5px">件</Text>
                    </Text>
                  </>):(<></>)}
              </Flex>
          </Flex>
        </View>
        <Flex display={{base:"none",large:"inline-flex"}} backgroundColor={tokens.colors.blue[90]} width="100%" justifyContent="flex-end" alignItems="center" gap="0">
          <Button className="headerLinkMenu" variation="link" colorTheme="info" borderRadius="0" onClick={handleOpenGuidePage} isDisabled={!isStatusOk} fontSize="1.6rem" padding="0.8rem 1.6rem" >
            <View as="span" height="2.4rem" lineHeight="2.4rem">企業INDEXナビとは？</View></Button>
          <Button className="headerLinkMenu" variation="link" colorTheme="info" borderRadius="0" onClick={handleOpenGuideUsage} isDisabled={!isStatusOk} fontSize="1.6rem" marginRight="1.6rem" padding="0.8rem 1.6rem">
            {isStatusOk ? 
              <img src={IcoPdf} width="20px" height="20px" style={{ paddingTop:"4px" }} /> : 
              <img src={IcoPdfDisabled} width="20px" height="20px" style={{ paddingTop:"4px" }} />
            }
            <View as="span" paddingLeft="0.7rem" height="2.4rem" lineHeight="2.4rem">活用ガイド</View>
            </Button>
          <Button className="headerLinkMenu" variation="link" colorTheme="info" borderRadius="0" onClick={handleOpenUsageTips} isDisabled={!isStatusOk} fontSize="1.6rem" padding="0.8rem 1.6rem" >
            <View as="span" height="2.4rem" lineHeight="2.4rem">活用のコツ</View></Button>
          <Button className="orangeHoverButton orangeHoverButtonDarkblueBackground" variation="menu" borderRadius="0" padding="0.8rem 1.6rem" onClick={handleOpenGSearchDBS} isDisabled={!isStatusOk} fontSize="1.6rem" backgroundColor={tokens.colors.red[60]} style={{zIndex:101}} gap="5px" marginRight="0.5rem">
            <MdSearch size="1.4em"/>
            <View as="span" paddingBottom="0.2rem">G-Searchで深掘り</View>
            <RxExternalLink style={{width:"1.2em",height:"1.2em"}}/>
          </Button>
          <View height="5.7rem" width="20rem">
            <Accordion.Container backgroundColor={tokens.colors.blue[90]} >
              <Accordion.Item style={{border:'none'}}>
                <Accordion.Trigger className="headerAccordionMenuTrigger" style={{ height:'5.7rem' ,display: 'flex', justifyContent: 'center', gap: '1rem',boxShadow:'none'}}>
                  <FaRegUser size="1.2em" />
                  <Text color={tokens.colors.white} fontSize="1.6rem" fontWeight="bold" paddingBottom="0.2rem">会員メニュー</Text>
                  <Accordion.Icon fontSize="3rem" style={{ display:"flex",alignItems:"center" }}/>
                </Accordion.Trigger>
                <Accordion.Content style={{ borderTop:"1px solid white" }} padding="0px 0.5rem 0px 0.5rem">
                  <Button className="darkblueHoverMenu" variation="menu" borderRadius="0" width="100%" backgroundColor={tokens.colors.blue[90]} onClick={handleOpenProfile} fontSize={tokens.fontSizes.large} padding="0 1rem 0 1rem"
                    isDisabled={!isStatusOk}>
                    <View width="100%" padding="0.75rem 0" style={{ borderBottom:'1px solid white'}}>
                      <View as="span" marginLeft="0" marginRight="0.5rem">登録内容確認・変更</View>
                      <RxExternalLink style={{width:"1.4em",height:"1.4em",transform:"translateY(4px)"}}/>
                    </View>
                  </Button>
                </Accordion.Content>
                
                <Accordion.Content padding="0 0.5rem 0 0.5rem">
                  <Button className="darkblueHoverMenu" variation="menu" borderRadius="0" width="100%" backgroundColor={tokens.colors.blue[90]} onClick={handleNavigateAddPurchase} fontSize={tokens.fontSizes.large} padding="0 1rem 0 1rem"
                    isDisabled={!isStatusOk || !(tokenInfo&&tokenInfo.plan=='basic'&&tokenInfo.role=='ADMIN')}>
                    <View width="100%" padding="0.9rem 0" style={{ borderBottom:'1px solid white'}}>追加購入</View>
                  </Button>
                </Accordion.Content>
                
                <Accordion.Content padding="0 0.5rem 0 0.5rem">
                  <Button className="darkblueHoverMenu" variation="menu" borderRadius="0" width="100%" backgroundColor={tokens.colors.blue[90]} onClick={handleNavigateDownloadLog} fontSize={tokens.fontSizes.large} padding="0 1rem 0 1rem"
                    isDisabled={!isStatusOk}>
                    <View width="100%" padding="0.9rem 0" style={{ borderBottom:'1px solid white'}}>CSVダウンロード履歴</View>
                  </Button>
                </Accordion.Content>
                
                <Accordion.Content padding="0 0.5rem 0 0.5rem">
                  <Button className="darkblueHoverMenu" variation="menu" borderRadius="0" width="100%" backgroundColor={tokens.colors.blue[90]} onClick={handleNavigateSearchFilterList} fontSize={tokens.fontSizes.large} padding="0 1rem 0 1rem"
                    isDisabled={!isStatusOk || !(tokenInfo&&tokenInfo.plan=='basic')}>
                    <View width="100%" padding="0.9rem 0" style={{ borderBottom:'1px solid white'}}>保存検索条件</View>
                  </Button>
                </Accordion.Content>

                <Accordion.Content padding="0 0.5rem 0 0.5rem">
                  <Button className="darkblueHoverMenu" variation="menu" borderRadius="0" width="100%" backgroundColor={tokens.colors.blue[90]} onClick={handleNavigatePlanChange} fontSize={tokens.fontSizes.large} padding="0 1rem 0 1rem"
                    isDisabled={!isStatusOk || !(tokenInfo&&tokenInfo.role=='ADMIN')}>
                    <View width="100%" padding="0.9rem 0" style={{ borderBottom:'1px solid white'}}>プラン変更</View>
                  </Button>
                </Accordion.Content>
                
                <Accordion.Content padding="0 0.5rem 0.5rem 0.5rem">
                  <Button className="darkblueHoverMenu" variation="menu" borderRadius="0" width="100%" backgroundColor={tokens.colors.blue[90]} onClick={handleOpenGHorizonPurchase} fontSize={tokens.fontSizes.large} padding="0 1rem 0 1rem"
                    isDisabled={!isStatusOk || !(tokenInfo&&tokenInfo.role=='ADMIN')}>
                    <View width="100%" padding="0.725rem 0" >
                      <View as="span" marginLeft="0" marginRight="0.5rem">利用状況照会</View>
                      <RxExternalLink style={{width:"1.4em",height:"1.4em",transform:"translateY(4px)"}}/>
                    </View>
                  </Button>
                </Accordion.Content>
              </Accordion.Item>
            </Accordion.Container>
          </View>
        </Flex>
                <View height="auto" width="100%" backgroundColor={tokens.colors.blue[90]} padding="1.6rem" position="absolute" top="55px" left="0" display={{base:isOpenHeaderMenu ? "block" : "none",large:"none"}} style={{zIndex:300}} >
          <Button variation="menu" className="darkblueHoverMenu" padding="0.8rem 1.6rem" fontSize="1.4rem" borderRadius="0" onClick={handleOpenGuidePage} isDisabled={!isStatusOk} justifyContent="flex-start"  width="100%" >企業INDEXナビとは？</Button>
          <Button variation="menu" className="darkblueHoverMenu pdfIconHoverButton" padding="0.8rem 1.6rem" fontSize="1.4rem" borderRadius="0" onClick={handleOpenGuideUsage} isDisabled={!isStatusOk}  justifyContent="flex-start" width="100%" marginTop="5px">
            {isStatusOk ? 
              <>
                <img src={IcoPdf} className="headerIcoPdf" width="20px" height="20px" />
                <img src={IcoPdfHover} className="headerIcoPdfHover" width="20px" height="20px" />
              </>
              : 
              <img src={IcoPdfDisabled} width="20px" height="20px" />
            }
            <View as="span" marginLeft="0.7rem">活用ガイド</View>
          </Button>
          <Button variation="menu" className="darkblueHoverMenu" padding="0.8rem 1.6rem" fontSize="1.4rem" borderRadius="0" onClick={handleOpenUsageTips} isDisabled={!isStatusOk} justifyContent="flex-start"  width="100%" >活用のコツ</Button>
          <Button className="orangeHoverButton orangeHoverButtonDarkblueBackground" variation="menu" borderRadius="0" height="4rem" onClick={handleOpenGSearchDBS} isDisabled={!isStatusOk} fontSize="1.4rem" backgroundColor={tokens.colors.red[60]} style={{zIndex:101}} width="100%" gap="5px" marginTop="15px">
            <Flex as="p" justifyContent="center" margin="0 auto" lineHeight="28.5px" gap="0.5rem" alignItems="center">
              <MdSearch size="2.5rem" />
              <View as="span">G-Searchで深掘り</View>
              <RxExternalLink style={{width:"1em",height:"1em"}}/>
            </Flex>
          </Button>
          <View as="div" fontSize={tokens.fontSizes.xl} width="100%" marginTop="20px" paddingLeft="10px">
            <Flex as="p" lineHeight="27.5px" gap="1rem" color={tokens.colors.white} >
              <FaRegUser size="18px" style={{marginTop:"3px"}} />
              <Text color={tokens.colors.white} fontSize="1.4rem" fontWeight="bold">会員メニュー</Text>
            </Flex>
          </View>
          <View paddingLeft="30px">
            <Button variation="menu" className="darkblueHoverMenu" padding="0.7rem 1.4rem 0.7rem 0.75rem" fontSize="1.3rem" borderRadius="0" width="100%" onClick={handleOpenProfile}
              isDisabled={!isStatusOk} >
              <View width="100%" height="25px" lineHeight="25px">
                <View as="span" marginLeft="0" marginRight="0.5rem">登録内容確認・変更</View>
                <RxExternalLink style={{width:"1.4em",height:"1.4em",transform:"translateY(4px)"}}/>
              </View>
            </Button>
          </View>
          <View paddingLeft="30px">
            <Button variation="menu" className="darkblueHoverMenu"  padding="0.7rem 1.4rem 0.7rem 0.75rem" fontSize="1.3rem" borderRadius="0" width="100%" onClick={handleNavigateAddPurchase}
              isDisabled={!isStatusOk || !(tokenInfo&&tokenInfo.plan=='basic'&&tokenInfo.role=='ADMIN')}>
              <View width="100%" height="25px" lineHeight="25px">追加購入</View>
            </Button>
          </View>
          <View paddingLeft="30px">
            <Button variation="menu" className="darkblueHoverMenu" padding="0.7rem 1.4rem 0.7rem 0.75rem" fontSize="1.3rem" borderRadius="0" width="100%" onClick={handleNavigateDownloadLog}
              isDisabled={!isStatusOk}>
              <View width="100%" height="25px" lineHeight="25px">CSVダウンロード履歴</View>
            </Button>
          </View>
          <View paddingLeft="30px">
            <Button variation="menu" className="darkblueHoverMenu" padding="0.7rem 1.4rem 0.7rem 0.75rem" fontSize="1.3rem" borderRadius="0" width="100%" onClick={handleNavigateSearchFilterList}
              isDisabled={!isStatusOk || !(tokenInfo&&tokenInfo.plan=='basic')}>
              <View width="100%" height="25px" lineHeight="25px">保存検索条件</View>
            </Button>
          </View>
          <View paddingLeft="30px">
            <Button variation="menu" className="darkblueHoverMenu" padding="0.7rem 1.4rem 0.7rem 0.75rem" fontSize="1.3rem" borderRadius="0" width="100%" onClick={handleNavigatePlanChange}
              isDisabled={!isStatusOk || !(tokenInfo&&tokenInfo.role=='ADMIN')}>
              <View width="100%" height="25px" lineHeight="25px">プラン変更</View>
            </Button>
          </View>
          <View paddingLeft="30px" >
            <Button variation="menu" className="darkblueHoverMenu" padding="0.7rem 1.4rem 0.7rem 0.75rem" fontSize="1.3rem" borderRadius="0" width="100%" onClick={handleOpenGHorizonPurchase}
              isDisabled={!isStatusOk || !(tokenInfo&&tokenInfo.role=='ADMIN')}>
              <View width="100%" height="25px" lineHeight="25px">
                <View as="span" marginLeft="0" marginRight="0.5rem">利用状況照会</View>
                <RxExternalLink style={{width:"1.4em",height:"1.4em",transform:"translateY(4px)"}}/>
              </View>
            </Button>
          </View>
        </View>
      </View>
    </Flex>
  );
};

